import { pick, get } from 'lodash';
import { blogAppDefId } from '../../constants/apps';
import { getSerializedComponents, getComponnetRef, getIndexRef } from './sdk-utils';
import mapComponent from './component-mapper';
import {
  TOKEN,
  MIGRATION_STATUS_NOT_STARTED,
  MIGRATION_STATUS_STARTED,
  MIGRATION_STATUS_FINISHED,
  OLD_BLOG_FEED_PAGE_ID,
  OLD_BLOG_POST_PAGE_ID,
  OLD_BLOG_APP_PART,
} from './constants';
import { POST_WIDGET_ID, BLOG_WIDGET_ID } from '../../constants/widgets';
import { patchSettings } from '../settings';
import { normalizeColorThemeName, getTextPresets, getColorPresets } from './style-utils';

class MagicMigration {
  status = MIGRATION_STATUS_NOT_STARTED;

  constructor(context, options = {}) {
    this.context = context;
    this.options = options;
  }

  async run() {
    if (!(await this.canMigrate())) {
      return;
    }

    if (!(await this.disableAutoSave())) {
      return;
    }

    this.status = MIGRATION_STATUS_STARTED;

    await this.convertOldBlogPages();
    await this.replaceOldBlogWidgets();
    await this.removeNewBlogPages();
    await this.saveStyleParams();

    this.status = MIGRATION_STATUS_FINISHED;
  }

  async disableAutoSave() {
    return (await this.context.sdk.document.initAutosave({ enabled: false })) === false;
  }

  async canMigrate() {
    this.pages = await this.context.sdk.pages.data.getAll();

    this.pages.forEach((page) => {
      if (page.type === 'AppPage' && page.appPageId === OLD_BLOG_FEED_PAGE_ID) {
        this.oldBlogFeedPageRef = { id: page.id, type: 'DESKTOP' };
        this.oldBlogFeedPage = page;
      } else if (page.type === 'AppPage' && page.appPageId === OLD_BLOG_POST_PAGE_ID) {
        this.oldBlogPostPageRef = { id: page.id, type: 'DESKTOP' };
        this.oldBlogPostPage = page;
      } else if (page.type === 'Page' && page.managingAppDefId === blogAppDefId && page.tpaPageId === 'blog') {
        this.newBlogFeedPage = page;
      } else if (page.type === 'Page' && page.managingAppDefId === blogAppDefId && page.tpaPageId === 'post') {
        this.newBlogPostPage = page;
      }
    });

    return this.oldBlogFeedPage && this.oldBlogPostPage && this.newBlogFeedPage && this.newBlogPostPage;
  }

  async convertOldBlogPages() {
    const { sdk } = this.context;

    if (this.options.dryRun) {
      return;
    }

    await sdk.document.wixapps.convertAppPage(TOKEN, { pageRef: this.oldBlogFeedPageRef });
    await sdk.document.wixapps.convertAppPage(TOKEN, { pageRef: this.oldBlogPostPageRef });

    await sdk.pages.data.update(TOKEN, {
      pageRef: this.oldBlogFeedPageRef,
      data: {
        ...this.oldBlogFeedPage,
        ...pick(this.newBlogFeedPage, ['type', 'managingAppDefId', 'tpaApplicationId', 'tpaPageId']),
        appPageId: null,
        appPageType: null,
        appInnerID: null,
      },
    });

    await sdk.pages.data.update(TOKEN, {
      pageRef: this.oldBlogPostPageRef,
      data: {
        ...this.oldBlogPostPage,
        ...pick(this.newBlogPostPage, ['type', 'managingAppDefId', 'tpaApplicationId', 'tpaPageId', 'title']),
        appPageId: null,
        appPageType: null,
        appInnerID: null,
      },
    });

    await sdk.pages.data.update(TOKEN, {
      pageRef: this.newBlogFeedPage,
      data: {
        ...this.newBlogFeedPage,
        tpaPageId: '',
        tpaApplicationId: 0,
      },
    });

    await sdk.pages.data.update(TOKEN, {
      pageRef: this.newBlogPostPage,
      data: {
        ...this.newBlogPostPage,
        tpaPageId: '',
        tpaApplicationId: 0,
      },
    });
  }

  isOldBlogComponent(id) {
    const OLD_BLOG_APP_PART_IDS = Object.values(OLD_BLOG_APP_PART);
    return OLD_BLOG_APP_PART_IDS.includes(id);
  }

  async replaceOldBlogWidgets() {
    const { sdk } = this.context;

    const oldBlogComponents = await getSerializedComponents({
      sdk,
      predicate: ({ type }) => /(AppPart|RSSButton)/.test(type),
    });
    const componentsToReplace = [];

    await Promise.all(
      oldBlogComponents.map(async ({ componentRef, containerRef, pageRef, serialized }) => {
        const component = await mapComponent(serialized, { sdk, dryRun: this.options.dryRun });
        if (!component) {
          return;
        }

        if (!this.isOldBlogComponent(serialized.data.appPartName)) {
          return;
        }

        const widgetId = get(component, 'componentDefinition.data.widgetId');
        if (this.options.whiteList && !this.options.whiteList.includes(widgetId)) {
          return;
        }

        const isSectionWidget = [POST_WIDGET_ID, BLOG_WIDGET_ID].includes(widgetId);
        component.containerRef = isSectionWidget ? pageRef : containerRef;
        component.indexRef = await (isSectionWidget ? getIndexRef(sdk, componentRef) : componentRef);
        componentsToReplace.push(component);
      }),
    );

    for (const { componentDefinition, props, containerRef, indexRef } of componentsToReplace) {
      const componentIndex = await sdk.components.arrangement.getIndex(TOKEN, { componentRef: indexRef });
      await sdk.components.addAndAdjustLayout(TOKEN, {
        componentDefinition,
        pageRef: containerRef,
        optionalIndex: componentIndex + 1,
        ...props,
      });
    }

    if (this.options.dryRun) {
      return;
    }
    await Promise.all(
      oldBlogComponents.map(({ componentRef, serialized }) => {
        if (!this.isOldBlogComponent(serialized.data.appPartName)) {
          return Promise.resolve();
        }

        return sdk.components.remove(TOKEN, { componentRef });
      }),
    );
  }

  async removeNewBlogPages() {
    if (this.options.dryRun) {
      return;
    }
    await Promise.all(
      [this.newBlogFeedPage, this.newBlogPostPage].map(
        (page) =>
          page &&
          this.context.sdk.pages.remove(TOKEN, {
            pageRef: { id: page.id, type: 'DESKTOP' },
            shouldShowEditorRemovePanel: false,
          }),
      ),
    );
  }

  async saveStyleParams() {
    const { sdk } = this.context;

    const [appInstance, textPresets, colorPresets, postComponentRef] = await Promise.all([
      sdk.document.info.getAppInstance(TOKEN),
      sdk.theme.fonts.getMap(TOKEN).then(getTextPresets),
      sdk.theme.colors.getAll(TOKEN).then(getColorPresets),
      getComponnetRef(sdk, POST_WIDGET_ID),
    ]);
    const style = await sdk.tpa.getStyleParams(TOKEN, { compRef: postComponentRef }).then(normalizeColorThemeName);
    await patchSettings(appInstance, postComponentRef.id, 'draft', { textPresets, colorPresets, style });
  }

  // TODO remove when done
  async debugPages() {
    this.pages = await this.context.sdk.pages.data.getAll();
    const serializedPages = await Promise.all(
      this.pages.map((p) =>
        this.context.sdk.document.pages.serialize(TOKEN, {
          pageRef: { id: p.id },
          maintainIdentifiers: false,
        }),
      ),
    );
    console.log(serializedPages);
  }
}

export default MagicMigration;
